import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import {
  SelectComponent, Spinner, Button, Seo,
} from '../../components'
import IconDownload from '../../assets/icons/pacificcross_icons_download.svg'
import SearchIcon from '../../assets/icons/pacificcross_icons_search.svg'
import './find-hospital-styles.scss'

const FindHospitalView = ({
  data,
  loading,
  cities,
  types,
  onHandleFilterType,
  onHandleFilterCity,
  city,
  type,
  onHandleMore,
  debouncedChangeHandler,
  restItems,
}) => {
  const { t } = useTranslation()
  const optionsCity = _.reduce(
    cities,
    (result, value, key) => {
      result.push({
        value: key,
        label: value,
      })
      return result
    },
    [
      {
        value: null,
        label: 'All',
      },
    ],
  )
  const optionsType = _.reduce(
    types,
    (result, value, key) => {
      result.push({
        value: key,
        label: value,
      })
      return result
    },
    [
      {
        value: null,
        label: 'All',
      },
    ],
  )

  const column = !_.isEmpty(data) && Object.keys(data[0])
  // get table heading data
  const ThData = () => !_.isEmpty(data)
    && column.map((d, idx) => (
      <th className="findHospital__table__head" key={idx}>
        {t(`findHospital.table.${d}`)}
      </th>
    ))
  // get table row data
  const tdData = () => !_.isEmpty(data)
    && data.map((d, idx) => (
      <tr key={idx}>
        {column.map((v, index) => {
          let text
          switch (v) {
            case 'providerType':
              text = _.head(_.filter(optionsType, { value: d[v] })).label
              break
            case 'city':
              text = _.head(_.filter(optionsCity, { value: d[v] })).label
              break
            default:
              text = d[v]
              break
          }

          return <td key={index}>{text}</td>
        })}
      </tr>
    ))

  return (
    <div className="container-fluid">
      <Seo title={`${t('seo.title')}: Find your Hospital`} />
      <div className="container-xxl">
        <div className="findHospital__head">
          <h1 className="page-title">{t('findHospital.title')}</h1>
        </div>
        <div className="findHospital__filters ">
          <div className="row">
            <div className="col-12 col-sm-3 findHospital__search order-1">
              <SelectComponent
                label={t('findHospital.filter.city')}
                placeholder={t('findHospital.filter.cityPlaceholder', {
                  number: optionsCity.length,
                })}
                options={optionsCity}
                onChange={onHandleFilterCity}
                selectedOption={city}
              />
            </div>
            <div className="col-12 col-sm-3 findHospital__search order-2">
              <SelectComponent
                label={t('findHospital.filter.providerType')}
                placeholder={t('findHospital.filter.providerTypePlaceholder', {
                  number: optionsType.length,
                })}
                options={optionsType}
                onChange={onHandleFilterType}
                selectedOption={type}
              />
            </div>
            <div className="col-12 col-sm-3 findHospital__download order-4 order-sm-3">
              <p className="findHospital__download__text">
                <IconDownload width="20" download />
                {t('findHospital.download')}
              </p>
            </div>
            <div className="col-12 col-sm-3 findHospital__search order-3 order-sm-4">
              <span className="findHospital__search__label">
                {t('findHospital.search')}
              </span>
              <div className="findHospital__inputSearch">
                <SearchIcon width="20" fill="#DEDEDE" />
                <input onChange={debouncedChangeHandler} placeholder="Search" />
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <Spinner loading={loading} />
        ) : (
          <React.Fragment>
            <div className="table-responsive">
              <table className="table findHospital__table">
                <thead>
                  <tr>{ThData()}</tr>
                </thead>
                <tbody>{tdData()}</tbody>
              </table>
            </div>
            <div className="findHospital__button">
              {restItems > 0 && (
                <Button
                  outline={true}
                  onClick={onHandleMore}
                  text={t('findHospital.buttonMore', { items: restItems })}
                />
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default FindHospitalView
